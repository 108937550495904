import React from 'react';
import Layout from "../components/layout"
import Parser from 'html-react-parser';
import * as showdown from 'showdown';
import { Link } from 'gatsby'
import moment from 'moment';

const converter = new showdown.Converter();

export default function JobTemplate({ location, pageContext }) {
    console.log("page context",pageContext.job)
    return (
        <Layout location={location}>
            <div className="flex justify-start ml-16 pt-16">
                    <Link to="/careers">   <div className=" underline">
                        {/* <ArrowLeftIcon className="inline h-12 w-12" aria-hidden="false" />  */}
                        <h2 className="inline text-sm font-bold leading-normal text-gray-600  hover:text-orange-primary">Back to Careers </h2> </div>
                    </Link>
                </div>
                <div className="flex flex-col gap-8 pt-16 justify-center items-center px-8 md:px-16">
                    <h1 className="font-heading1" max-w-6xl > {pageContext.job.role}</h1>
                    <div className="font-body1 blog  max-w-6xl">
                    {Parser(converter.makeHtml(pageContext.job.job_description))}
                    </div>
                </div>
        </Layout>
    )
}